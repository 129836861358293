<template>
  <div
    :class="{
      'q-mb-sm': searchFilterTags.length > 0
    }"
  >
    <div class="row items-center q-gutter-sm">
      <q-chip
        v-for="(filterTagData, i) in searchFilterTags"
        :key="i + '_searchResultFilterTag'"
        removable
        icon-remove="sym_r_cancel"
        @remove="filterTagData.removeFunction"
        color="secondary"
        text-color="white"
        :disable="!$store.state.search.loaded"
      >
        <span class="q-mr-xs">{{ filterTagData.label }}</span>
      </q-chip>
      <q-btn
        v-show="searchFilterTags.length > 0"
        color="secondary"
        flat
        label="Reset All"
        :ripple="false"
        @click="resetFilters"
        style="padding: 4px 8px"
        :disable="!$store.state.search.loaded"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterTags",
  data() {
    return {
      searchFilterTags: []
    };
  },
  computed: {
    searchFilterModelsActive() {
      return this.$store.state.search.filter.modelsActive;
    },
    searchKeyword() {
      return this.$store.state.search.keyword;
    }
  },
  watch: {
    searchFilterModelsActive: {
      handler() {
        this.generateSearchFilterTags();
      },
      deep: true
    },
    searchKeyword() {
      this.generateSearchFilterTags();
    }
  },
  mounted() {
    this.generateSearchFilterTags();
  },
  methods: {
    generateSearchFilterTags() {
      let filterTags = [];
      let filterModelDefaults = this.$store.state.search.filter.modelDefaults;
      let vm = this;
      Object.keys(this.searchFilterModelsActive).forEach(modelKey => {
        let rangeModels = ["year", "mileage", "price"];
        if (rangeModels.includes(modelKey)) {
          let valueMin = this.searchFilterModelsActive[modelKey].min;
          let valueMax = this.searchFilterModelsActive[modelKey].max;
          if (
            valueMin === filterModelDefaults[modelKey].min &&
            valueMax === filterModelDefaults[modelKey].max
          ) {
            return;
          }
          if (modelKey === "mileage" || modelKey === "price") {
            valueMin = this.formatNumber(valueMin);
            valueMax = this.formatNumber(valueMax);
            if (modelKey === "price") {
              valueMin = "$" + valueMin;
              valueMax = "$" + valueMax;
            }
          }
          let label = valueMin + " - " + valueMax;
          if (modelKey === "mileage") {
            label += " Miles";
          }
          filterTags.push({
            label: label,
            removeFunction: function() {
              vm.resetFilters(modelKey);
            }
          });
        } else {
          if (Array.isArray(this.searchFilterModelsActive[modelKey])) {
            this.searchFilterModelsActive[modelKey].forEach(filterValue => {
              filterTags.push({
                label: this.$store.getters[
                  "search/filterLabelByModelKeyValue"
                ]({ modelKey, value: filterValue }),
                removeFunction: function() {
                  vm.removeFilterValue(modelKey, filterValue);
                }
              });
            });
          } else {
            if (this.searchFilterModelsActive[modelKey].length > 0) {
              filterTags.push({
                label: this.searchFilterModelsActive[modelKey],
                removeFunction: function() {
                  vm.removeFilterValue(modelKey, "");
                }
              });
            }
          }
        }
      });

      if (this.searchKeyword.length > 0) {
        filterTags.push({
          label: this.searchKeyword,
          removeFunction: function() {
            vm.resetFilters();
          }
        });
      }

      this.searchFilterTags = filterTags;
    },
    removeFilterValue(modelKey, value) {
      this.$store.commit("search/REMOVE_FILTER_MODEL_VALUE", {
        modelKey,
        value
      });
      this.resetSearch();
    },
    resetFilters(modelKey = "") {
      this.$store.commit("search/RESET_FILTERS", modelKey);
      this.resetSearch();
    },
    resetSearch() {
      this.$store.commit("search/RESET_PAGINATION");
      this.$store.commit("search/RESET_KEYWORD");
      this.$store.commit("search/SET_LOADING");
    }
  }
};
</script>

<style lang="scss" scoped></style>
