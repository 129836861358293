<template>
  <q-form
    @submit="filterSearchResults"
    @reset="resetFilters"
    class="filters-sidebar"
    :disable="!$store.state.search.loaded"
  >
    <div class="filters-sidebar__card">
      <q-list>
        <q-item class="filters-sidebar__header">
          <q-item-section>
            <h6 class="row no-wrap items-center q-ma-none">
              <q-icon name="sym_r_place" class="q-mr-sm" size="18px" />
              Location
            </h6>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>
            <q-input
              dense
              filled
              v-model="location.postalCode"
              label="Zip Code"
              :error="location.postalCodeInput.error != null"
              :disable="
                !$store.state.search.loaded || location.postalCodeInput.disabled
              "
              inputmode="numeric"
              pattern="[0-9]*"
              mask="#####"
              no-error-icon
              class="q-pb-sm q-mb-xs"
            >
              <template v-slot:append>
                <q-btn
                  dense
                  label="Go"
                  type="submit"
                  color="secondary"
                  class="q-my-sm q-ml-sm"
                  :disable="
                    !$store.state.search.loaded ||
                      location.postalCodeInput.disabled ||
                      location.postalCodeInput.disableSubmit
                  "
                  style="padding: 2px 8px;"
                  @click="updateSearchLocation()"
                />
              </template>
            </q-input>
            <q-select
              dense
              filled
              v-model="location.radius"
              :options="locationRadiusOptions"
              label="Search Within"
              :disable="
                !$store.state.search.loaded ||
                  location.postalCodeInput.disabled ||
                  location.postalCodeInput.disableSubmit
              "
              emit-value
              map-options
            />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="filters-sidebar__card q-mt-lg">
      <q-list>
        <q-item class="filters-sidebar__header">
          <q-item-section>
            <h6 class="row no-wrap items-center q-ma-none">
              <q-icon name="sym_r_tune" class="q-mr-sm" size="21px" />
              Filters
            </h6>
          </q-item-section>
          <q-item-section avatar>
            <q-btn
              type="reset"
              color="secondary"
              flat
              label="Reset"
              style="font-size: 0.9em; padding: 4px 8px"
              :disable="!$store.state.search.loaded"
            />
          </q-item-section>
        </q-item>

        <q-expansion-item expand-separator label="Make & Model">
          <q-expansion-item
            v-for="(models, makeId) in $store.state.search.filter.options
              .make_model_facet"
            :key="makeId + '_makeSearchFilterOption'"
            :header-inset-level="0"
            dense
            dense-toggle
            expand-separator
          >
            <template v-slot:header>
              <q-item-section>
                <q-item-label>{{ getMakeName(makeId) }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                {{ models.length > 0 ? models[0].make_count : null }}
              </q-item-section>
            </template>

            <q-item
              :key="makeId + '_all_modelSearchFilterOption'"
              :inset-level="0.3"
              v-ripple
              tag="label"
              dense
              :disable="!$store.state.search.loaded"
            >
              <q-item-section side>
                <q-checkbox
                  dense
                  v-model="searchFilterModels.make"
                  :val="makeId"
                  color="secondary"
                  :disable="!$store.state.search.loaded"
                  @click="filterSearchResults"
                />
              </q-item-section>
              <q-item-section>
                All Models
              </q-item-section>
            </q-item>

            <q-item
              v-for="model in models"
              :key="makeId + '_' + model.value + '_modelSearchFilterOption'"
              :inset-level="0.3"
              v-ripple
              tag="label"
              dense
              :disable="!$store.state.search.loaded"
            >
              <q-item-section side>
                <q-checkbox
                  dense
                  v-model="searchFilterModels.model"
                  :val="model.value"
                  color="secondary"
                  :disable="!$store.state.search.loaded"
                  @click="filterSearchResults"
                />
              </q-item-section>
              <q-item-section>
                <span class="ellipsis">{{ model.label }}</span>
              </q-item-section>
              <q-item-section>
                <small class="text-right">{{ model.count }}</small>
              </q-item-section>
            </q-item>
          </q-expansion-item>
        </q-expansion-item>

        <q-expansion-item expand-separator>
          <template v-slot:header>
            <div
              class="q-item__section--main flex justify-between items-center full-width"
            >
              <span>Body Type</span>
              <!--<q-badge v-show="searchFilterModels.bodyType.length > 0" rounded outline color="secondary" :label="searchFilterModels.bodyType.length" class="--shadow__bottom" style="padding: 3px 6px; font-weight: 900" />-->
            </div>
          </template>
          <q-item
            v-for="(option, i) in searchFilterOptions.bodyType"
            :key="i + '_bodyTypeSearchFilterOption'"
            :inset-level="0"
            v-ripple
            tag="label"
            dense
            :disable="!$store.state.search.loaded"
          >
            <q-item-section side>
              <q-checkbox
                dense
                v-model="searchFilterModels.bodyType"
                :val="option.value"
                color="secondary"
                :disable="!$store.state.search.loaded"
                @click="filterSearchResults"
              />
            </q-item-section>
            <q-item-section>
              {{ option.label }}
            </q-item-section>
            <q-item-section>
              <small class="text-right">{{ option.count }}</small>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item expand-separator label="Condition">
          <q-item
            v-for="(option, i) in searchFilterOptions.condition"
            :key="i + '_conditionSearchFilterOption'"
            :inset-level="0"
            v-ripple
            tag="label"
            dense
            :disable="!$store.state.search.loaded"
          >
            <q-item-section side>
              <q-checkbox
                dense
                v-model="searchFilterModels.condition"
                :val="option.value"
                color="secondary"
                :disable="!$store.state.search.loaded"
                @click="filterSearchResults"
              />
            </q-item-section>
            <q-item-section>
              {{ option.label }}
            </q-item-section>
            <q-item-section>
              <small class="text-right">{{ option.count }}</small>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item expand-separator label="Price">
          <q-item>
            <div class="q-col-gutter-md row">
              <q-input
                filled
                dense
                v-model.number="searchFilterModels.price.min"
                label="Min Price"
                key="minPrice_SearchFilterInput"
                inputmode="numeric"
                pattern="[0-9]*"
                class="col-6"
                :disable="!$store.state.search.loaded"
                @blur="filterSearchResults"
              />
              <q-input
                filled
                dense
                v-model.number="searchFilterModels.price.max"
                label="Max Price"
                key="maxPrice_SearchFilterInput"
                inputmode="numeric"
                pattern="[0-9]*"
                class="col-6"
                :disable="!$store.state.search.loaded"
                @blur="filterSearchResults"
              />
            </div>
          </q-item>
          <q-item>
            <q-range
              :model-value="searchFilterModels.price"
              @change="
                val => {
                  searchFilterModels.price = val;
                  filterSearchResults();
                }
              "
              :min="searchFilterModelDefaults.price.min"
              :max="searchFilterModelDefaults.price.max"
              :step="500"
              color="secondary"
              label
              key="price_SearchFilterRange"
              style="width:94%;margin:0 auto"
              :disable="!$store.state.search.loaded"
            />
          </q-item>
        </q-expansion-item>

        <q-expansion-item expand-separator label="Mileage">
          <q-item>
            <div class="q-col-gutter-md row">
              <q-input
                filled
                dense
                v-model.number="searchFilterModels.mileage.min"
                label="Min Mileage"
                key="minMileage_SearchFilterInput"
                inputmode="numeric"
                pattern="[0-9]*"
                class="col-6"
                :disable="!$store.state.search.loaded"
                @blur="filterSearchResults"
              />
              <q-input
                filled
                dense
                v-model.number="searchFilterModels.mileage.max"
                label="Max Mileage"
                key="maxMileage_SearchFilterInput"
                inputmode="numeric"
                pattern="[0-9]*"
                class="col-6"
                :disable="!$store.state.search.loaded"
                @blur="filterSearchResults"
              />
            </div>
          </q-item>
          <q-item>
            <q-range
              :model-value="searchFilterModels.mileage"
              @change="
                val => {
                  searchFilterModels.mileage = val;
                  filterSearchResults();
                }
              "
              :min="searchFilterModelDefaults.mileage.min"
              :max="searchFilterModelDefaults.mileage.max"
              :step="500"
              color="secondary"
              label
              key="mileage_SearchFilterRange"
              style="width:94%;margin:0 auto"
              :disable="!$store.state.search.loaded"
            />
          </q-item>
        </q-expansion-item>

        <q-expansion-item expand-separator label="Year">
          <q-item>
            <div class="q-col-gutter-md row">
              <q-input
                filled
                dense
                v-model.number="searchFilterModels.year.min"
                label="Min Year"
                key="minYear_SearchFilterInput"
                inputmode="numeric"
                pattern="[0-9]*"
                class="col-6"
                :disable="!$store.state.search.loaded"
                @blur="filterSearchResults"
              />
              <q-input
                filled
                dense
                v-model.number="searchFilterModels.year.max"
                label="Max Year"
                key="maxYear_SearchFilterInput"
                inputmode="numeric"
                pattern="[0-9]*"
                class="col-6"
                :disable="!$store.state.search.loaded"
                @blur="filterSearchResults"
              />
            </div>
          </q-item>
          <q-item>
            <q-range
              :model-value="searchFilterModels.year"
              @change="
                val => {
                  searchFilterModels.year = val;
                  filterSearchResults();
                }
              "
              :min="searchFilterModelDefaults.year.min"
              :max="searchFilterModelDefaults.year.max"
              :step="1"
              color="secondary"
              label
              key="year_SearchFilterRange"
              style="width:94%;margin:0 auto"
              :disable="!$store.state.search.loaded"
            />
          </q-item>
        </q-expansion-item>

        <q-expansion-item expand-separator label="Color">
          <q-item
            v-for="(option, i) in searchFilterOptions.color"
            :key="i + '_colorSearchFilterOption'"
            :inset-level="0"
            v-ripple
            tag="label"
            dense
            :disable="!$store.state.search.loaded"
          >
            <q-item-section side>
              <q-checkbox
                dense
                v-model="searchFilterModels.color"
                :val="option.value"
                color="secondary"
                :disable="!$store.state.search.loaded"
                @click="filterSearchResults"
              />
            </q-item-section>
            <q-item-section>
              {{ option.label }}
            </q-item-section>
            <q-item-section side>
              {{ option.count }}
            </q-item-section>
            <q-item-section side>
              <div
                v-if="option.value.toLowerCase() === 'white'"
                :style="{ background: option.color }"
                style="border: 2px solid #E0E0E0; border-radius: 200px; width: 20px; height: 20px"
              />
              <div
                v-else-if="option.value.toLowerCase() === 'brown'"
                style="background: #DBC5A4; border-radius: 200px; width: 20px; height: 20px; position: relative"
              >
                <div
                  style="position: absolute; top: 0; left: 0; width: 50%; height: 100%; background: #914915; border-top-left-radius: 200px; border-bottom-left-radius: 200px"
                ></div>
              </div>
              <div
                v-else
                :style="{
                  background:
                    $store.state.search.colorMap[option.value.toLowerCase()]
                }"
                style="border-radius: 200px; width: 20px; height: 20px"
              />
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item expand-separator label="Fuel Type">
          <q-item
            v-for="(option, i) in searchFilterOptions.fuelType"
            :key="i + '_fuelTypeSearchFilterOption'"
            :inset-level="0"
            v-ripple
            tag="label"
            dense
            :disable="!$store.state.search.loaded"
          >
            <q-item-section side>
              <q-checkbox
                dense
                v-model="searchFilterModels.fuelType"
                :val="option.value"
                color="secondary"
                :disable="!$store.state.search.loaded"
                @click="filterSearchResults"
              />
            </q-item-section>
            <q-item-section>
              {{ option.label }}
            </q-item-section>
            <q-item-section>
              <small class="text-right">{{ option.count }}</small>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item expand-separator label="Transmission">
          <q-item
            v-for="(option, i) in searchFilterOptions.transmission"
            :key="i + '_transmissionSearchFilterOption'"
            :inset-level="0"
            v-ripple
            tag="label"
            dense
            :disable="!$store.state.search.loaded"
          >
            <q-item-section side>
              <q-checkbox
                dense
                v-model="searchFilterModels.transmission"
                :val="option.value"
                color="secondary"
                :disable="!$store.state.search.loaded"
                @click="filterSearchResults"
              />
            </q-item-section>
            <q-item-section>
              {{ option.label }}
            </q-item-section>
            <q-item-section>
              <small class="text-right">{{ option.count }}</small>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item expand-separator label="Engine Type">
          <q-item
            v-for="(option, i) in searchFilterOptions.engineType"
            :key="i + '_engineTypeSearchFilterOption'"
            :inset-level="0"
            v-ripple
            tag="label"
            dense
            :disable="!$store.state.search.loaded"
          >
            <q-item-section side>
              <q-checkbox
                dense
                v-model="searchFilterModels.engineType"
                :val="option.value"
                color="secondary"
                :disable="!$store.state.search.loaded"
                @click="filterSearchResults"
              />
            </q-item-section>
            <q-item-section>
              {{ option.label }}
            </q-item-section>
            <q-item-section>
              <small class="text-right">{{ option.count }}</small>
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </q-list>
    </div>
  </q-form>
</template>

<script>
export default {
  name: "FiltersSidebar",
  data() {
    return {
      makeIdsCheckboxState: {},
      location: {
        postalCode: this.$store.state.search.location.postalCode,
        postalCodeInput: {
          error: null,
          disableSubmit: false,
          disabled: false
        },
        radius: this.$store.state.search.location.radius
      }
    };
  },
  computed: {
    locationRadiusOptions() {
      let radiuses = [
        "10",
        "25",
        "50",
        "75",
        "100",
        "150",
        "200",
        "250",
        "500"
      ];
      let options = [];
      radiuses.forEach(radius => {
        options.push({ label: radius + " miles", value: radius });
      });
      return options;
    },
    searchFilterModels() {
      return this.$store.state.search.filter.models;
    },
    searchFilterOptions() {
      return this.$store.state.search.filter.options;
    },
    searchFilterModelDefaults() {
      return this.$store.state.search.filter.modelDefaults;
    },
    searchFilterModelsActive() {
      return this.$store.state.search.filter.modelsActive;
    },
    selectedFacets() {
      return Object.keys(this.searchFilterModelsActive).filter(key => {
        const value = this.searchFilterModelsActive[key];
        return Array.isArray(value) && value.length > 0;
      });
    },
    makesSorted() {
      return this.$store.getters["vehicleMakeModels/makesSorted"];
    }
  },
  watch: {
    "location.radius"() {
      this.updateSearchLocation();
    },
    "searchFilterModels.price.min"(val) {
      if (
        val.length === 0 ||
        val < this.searchFilterModelDefaults.price.min ||
        val > this.searchFilterModels.price.max
      ) {
        this.searchFilterModels.price.min = this.searchFilterModelDefaults.price.min;
      }
    },
    "searchFilterModels.price.max"(val) {
      if (
        val.length === 0 ||
        val > this.searchFilterModelDefaults.price.max ||
        val < this.searchFilterModels.price.min
      ) {
        this.searchFilterModels.price.max = this.searchFilterModelDefaults.price.max;
      }
    },
    "searchFilterModels.year.min"(val) {
      if (
        val.length === 0 ||
        val < this.searchFilterModelDefaults.year.min ||
        val > this.searchFilterModels.year.max
      ) {
        this.searchFilterModels.year.min = this.searchFilterModelDefaults.year.min;
      }
    },
    "searchFilterModels.year.max"(val) {
      if (
        val.length === 0 ||
        val > this.searchFilterModelDefaults.year.max ||
        val < this.searchFilterModels.year.min
      ) {
        this.searchFilterModels.year.max = this.searchFilterModelDefaults.year.max;
      }
    },
    "searchFilterModels.mileage.min"(val) {
      if (
        val.length === 0 ||
        val < this.searchFilterModelDefaults.mileage.min ||
        val > this.searchFilterModels.mileage.max
      ) {
        this.searchFilterModels.mileage.min = this.searchFilterModelDefaults.mileage.min;
      }
    },
    "searchFilterModels.mileage.max"(val) {
      if (
        val.length === 0 ||
        val > this.searchFilterModelDefaults.mileage.max ||
        val < this.searchFilterModels.mileage.min
      ) {
        this.searchFilterModels.mileage.max = this.searchFilterModelDefaults.mileage.max;
      }
    },
    "searchFilterModels.make"() {
      this.updateMakeIdsCheckboxState("make");
    },
    "searchFilterModels.model"() {
      this.updateMakeIdsCheckboxState("model");
    }
  },
  mounted() {
    this.initMakeIdsCheckboxState();
  },
  methods: {
    getMakeName(makeId) {
      return (
        this.$store.state.vehicleMakeModels.makeIdNameMap[makeId] || "Unknown"
      );
    },
    updateSearchLocation() {
      /* if((this.location.postalCode == this.$store.state.search.location.postalCode) && (this.location.radius == this.$store.state.search.location.radius)) {
        return;
      } */
      this.location.postalCodeInput.error = null;

      if (this.location.postalCode.length < 5) {
        this.location.postalCodeInput.error = "Invalid zip code.";
      }

      this.$store
        .dispatch("geolocation/VALIDATE_POSTAL_CODE", this.location.postalCode)
        .then(locationData => {
          this.location.postalCodeInput.error = null;

          this.$store.commit("search/SET_LOCATION", {
            postalCode: this.location.postalCode,
            city: locationData["city"],
            state: locationData["state"],
            radius: this.location.radius
          });

          this.$store.commit("search/RESET_PAGINATION");
          this.$store.commit("search/SET_FILTER_MODELS_ACTIVE");
          this.$store.commit("search/SET_LOADING");
        })
        .catch(error => {
          this.location.postalCodeInput.error = error;
        });
    },
    filterSearchResults() {
      this.$store
        .dispatch("search/CHECK_FILTER_MODELS_UPDATED")
        .then(updated => {
          if (updated) {
            this.$store.commit("search/RESET_KEYWORD");
            this.$store.commit("search/RESET_PAGINATION");
            this.$store.commit("search/SET_FILTER_MODELS_ACTIVE");
            this.$store.commit("search/SET_LOADING");
          }
        });
    },
    resetFilters() {
      this.$store.commit("search/RESET_KEYWORD");
      this.$store.commit("search/RESET_PAGINATION");
      this.$store.commit("search/RESET_FILTERS");
      this.$store.commit("search/SET_LOADING");
    },
    initMakeIdsCheckboxState() {
      Object.keys(
        this.$store.state.vehicleMakeModels.makeIdModelIdsMap
      ).forEach(makeId => {
        this.makeIdsCheckboxState[makeId] = false;
        let index = this.searchFilterModels.make.indexOf(makeId);
        if (index !== -1) {
          this.makeIdsCheckboxState[makeId] = true;
        } else {
          let thisMakeModelIds = this.$store.state.vehicleMakeModels
            .makeIdModelIdsMap[makeId];
          thisMakeModelIds.every(modelId => {
            if (this.searchFilterModels.model.includes(modelId)) {
              let index = this.searchFilterModels.model.indexOf(modelId);
              if (index !== -1) {
                this.makeIdsCheckboxState[makeId] = "intermediate";
                return false;
              }
            }
            return true;
          });
        }
      });
    },
    updateMakeIdsCheckboxState(filterModelUpdated) {
      Object.keys(
        this.$store.state.vehicleMakeModels.makeIdModelIdsMap
      ).forEach(makeId => {
        if (filterModelUpdated === "make") {
          this.toggleMakeModelCheckboxes(this.searchFilterModels.make, makeId);
        } else {
          this.toggleMakeCheckboxes(this.searchFilterModels.model, makeId);
        }
      });
    },
    resetMakeIdsCheckboxState() {
      Object.keys(this.$store.state.vehicleMakeModels.makeIdNameMap).forEach(
        makeId => {
          this.makeIdsCheckboxState[makeId] = false;
        }
      );
    },
    toggleMakeCheckboxes(value, makeId) {
      let thisMakeModelIds = this.$store.state.vehicleMakeModels
        .makeIdModelIdsMap[makeId];
      let makeHasActiveModels = false;
      thisMakeModelIds.every(modelId => {
        if (this.searchFilterModels.model.includes(modelId)) {
          let index = this.searchFilterModels.model.indexOf(modelId);
          if (index !== -1) {
            this.makeIdsCheckboxState[makeId] = "intermediate";
            makeHasActiveModels = true;
            return false;
          }
        }
        return true;
      });
      if (!makeHasActiveModels) {
        let index = this.searchFilterModels.make.indexOf(makeId);
        this.makeIdsCheckboxState[makeId] = index !== -1;
      } else {
        let index = this.searchFilterModels.make.indexOf(makeId);
        if (index !== -1) {
          this.searchFilterModels.make.splice(index, 1);
        }
      }
    },
    toggleMakeModelCheckboxes(value, makeId) {
      let index = this.searchFilterModels.make.indexOf(makeId);
      if (index !== -1) {
        this.makeIdsCheckboxState[makeId] = true;
        let thisMakeModelIds = this.$store.state.vehicleMakeModels
          .makeIdModelIdsMap[makeId];
        thisMakeModelIds.forEach(modelId => {
          if (this.searchFilterModels.model.includes(modelId)) {
            let index = this.searchFilterModels.model.indexOf(modelId);
            if (index !== -1) {
              this.searchFilterModels.model.splice(index, 1);
            }
          }
        });
      } else {
        let makeHasActiveModels = false;
        let thisMakeModelIds = this.$store.state.vehicleMakeModels
          .makeIdModelIdsMap[makeId];
        thisMakeModelIds.every(modelId => {
          if (this.searchFilterModels.model.includes(modelId)) {
            let index = this.searchFilterModels.model.indexOf(modelId);
            if (index !== -1) {
              makeHasActiveModels = true;
              return false;
            }
          }
          return true;
        });
        if (!makeHasActiveModels) {
          this.makeIdsCheckboxState[makeId] = false;
          let index = this.searchFilterModels.make.indexOf(makeId);
          if (index !== -1) {
            this.searchFilterModels.make.splice(index, 1);
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filters-sidebar {
  min-width: 240px;
  max-width: 240px;
  max-height: calc(100dvh - #{$titlebar-height} - (2 * #{$space-lg}));
  overflow-y: auto;
  position: sticky;
  top: calc(#{$titlebar-height} + #{$space-lg});
  padding-bottom: $space-sm;
  margin: 0 $space-lg $space-lg 0;
  border-radius: 4px;

  @include screen-sm {
    max-height: calc(10dvh - #{$titlebar-height} - (2 * #{$space-lg}));
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .filters-sidebar__card {
    background: color(background, light);
    border-radius: 4px;
    box-shadow: $shadow-bottom;
    padding-bottom: $space-sm;
    position: relative;

    .filters-sidebar__header {
      margin: $space-sm 0;
      background: color(background, very-light);
      border-radius: 4px 4px 0 0;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}
</style>
